<template>
  <div id="mscategoriesIndex">
    <ms-category-table />
  </div>
</template>

<script>
import MsCategoryTable from '@/components/ms_categories/MSCategoryTable'
export default {
  components: { MsCategoryTable },
  data () {
    return {}
  },
  computed: {
  }
}
</script>
