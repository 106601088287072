<template>
  <the-base-table
    ref="baseTable"
    url="ms_categories/"
    singularName="Categoria servicio médico"
    pluralName="Categorias de servicios médicos"
    :headers="headers"
    :filters="filters"
    :objectEdited.sync="msCategoryEdit"
    :objectDefault="msCategoryDefault"
    :showActionsColumn="isAdmin"
    :edit="true"
    :remove="false"
    :create="false"
  >
    <template v-slot:form="{ isEditing }">
      <ms-category-form
        :is-editing="isEditing"
        :ms_category="msCategoryEdit"
        @canceled="stopEditing()"
        @saved="refresh()"
        @error="error($event)"
      />
    </template>

    <template v-slot:row="{ item }">
      <td class="text-center">{{ item.id }}</td>
      <td>{{ item.category }}</td>
    </template>
  </the-base-table>
</template>

<script>
import TheBaseTable from '@/components/base/BaseTable'
import MsCategoryForm from '@/components/ms_categories/MSCategoryForm'
import Utils from '@/utils'

export default {
  data: () => ({
    msCategoryEdit: {
      category: '',
    },
    headers: [
      { text: 'ID', value: 'id', align: 'center', width: '10%' },
      {
        text: 'Categoria de Servicio médico',
        value: 'category',
        align: 'center',
      },
    ],
  }),
  computed: {
    filters() {
      const filter = {}
      return filter
    },
    isAdmin() {
      if (
        this.$store.getters['auth/user'].active_role === Utils.userRoles[2].id
      )
        return true
      return false
    },
    msCategoryDefault() {
      if (
        this.$store.getters['auth/user'].active_role === Utils.userRoles[2].id
      ) {
        return { id: -1, category: '' }
      }
      return undefined
    },
  },
  methods: {
    refresh() {
      this.$refs.baseTable.isEditing = false
      this.$refs.baseTable.fetch()
    },
    stopEditing() {
      this.$refs.baseTable.isEditing = false
    },
    error(event) {
      console.log(event)
    },
  },
  components: {
    TheBaseTable,
    MsCategoryForm,
  },
}
</script>
