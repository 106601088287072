<template>
  <the-base-form
    :url="url"
    :object="ms_category"
    :is-editing="isEditing"
    singularName="categoria de servicio médico"
    @canceled="$emit('canceled')"
    @saved="$emit('saved')"
    @error="$emit('error', $event)"
  >
    <v-flex xs12 sm12 md12>
      <ValidationProvider
        name="Categoria de servicio médico"
        rules="required"
        v-slot="{ errors }"
      >
        <v-text-field
          type="text"
          :value="ms_category.category"
          v-model="ms_category.category"
          label="Nombre de categoria"
          :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>
    </v-flex>
  </the-base-form>
</template>

<script>
import TheBaseForm from '@/components/base/BaseForm'
export default {
  data: () => ({}),
  components: {
    TheBaseForm,
  },
  props: {
    ms_category: Object,
    isEditing: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    url() {
      return 'ms_categories/'
    },
  },
}
</script>
